import throttle from 'lodash.throttle';
import { Elm } from './src/Main.elm';
import './scss/styles.scss';
import { initialise } from '../../common/Elm/bootstrapElm';

const { bootstrap, mount, unmount } = initialise([{
    elmModule: Elm.Main,
    domGetter: () => document.getElementById('app-changecourse'),
    flags: {
        countries: [],
    },
    configurePorts: (ports) => {
        document.addEventListener('scroll', throttle((e) => {
            if (ports.onScroll) {
                ports.onScroll.send(e);
            }
        }, 100));
    },
}]);

export {
    bootstrap,
    mount,
    unmount,
};
