/* eslint-disable no-param-reassign */
import singleSpaElm from './single-spa-elm';
import { keepFocusWithin } from './src/JsUtils/accessibility';
import { isIe11 } from './src/JsUtils/featureCheck';

let subscriptions = [];

const localStorageKeys = {
    i18nCache: 'i18nCache',
};

function getCachedBlurbs() {
    const debugBlurbs = localStorage.getItem('debugBlurbs') === 'true';
    return debugBlurbs
        ? {}
        : JSON.parse(localStorage.getItem(localStorageKeys.i18nCache) || '{}');
}

function mergeBlurbCaches(current, incoming) {
    return Object.entries(incoming).reduce((merged, [lang, lookup]) => {
        if (!merged[lang]) {
            merged[lang] = lookup;
            return merged;
        }
        merged[lang] = Object.entries(lookup).reduce((mergedLookup, [key, val]) => {
            mergedLookup[key] = val;
            return mergedLookup;
        }, merged[lang]);
        return merged;
    }, current);
}


// combine any query string / hash fragment params into a list of key value pairs
export function initialise(configs) {
    function extractUrlParams() {
        const qs = new URLSearchParams(window.location.search).entries();
        const hash = window.location.hash
            .slice(1)
            .split(';')
            .filter((p) => p !== '')
            .map((p) => p.split('='))
            .filter((p) => p instanceof Array && p.length === 2); // make sure that we only have pairs
        const merged = [...qs, ...hash];
        return merged;
    }

    const spaConfigs = configs.map((c) => ({
        elmMain: c.elmModule,
        domElementGetter: c.domGetter,
        flags: c.flags,
        configurePorts: c.configurePorts,
    }));

    const mergedExternalResources = configs.reduce((agg, c) => {
        if (c.externalResources) {
            return {
                ...agg,
                ...c.externalResources,
            };
        }
        return agg;
    }, {});

    const elmLifecycles = singleSpaElm(spaConfigs);

    const bootstrap = () => elmLifecycles.bootstrap();

    const mount = (props) => elmLifecycles.mount({
        ...props,
        url: window.location.pathname,
        fullUrl: window.location.href,
        urlParams: extractUrlParams(),
        cachedBlurbs: getCachedBlurbs(),
        externalBlurbs: mergedExternalResources,
        isMarketCN: window.location.hostname.toLowerCase().endsWith('.cn'),
    }).then((elmApps) => {
        subscriptions = [];

        elmApps.forEach((elm) => {
            if (!elm.ports) return;

            if (elm.ports.onLanguageChange) {
                subscriptions.push(
                    props.onLanguageChange({
                        callback: (lang) => {
                            elm.ports.onLanguageChange.send(lang);
                        },
                        translationsRequest: () => ({
                            keys: [],
                        }),
                    }),
                );
            }

            if (elm.ports.logout) {
                elm.ports.logout.subscribe(() => {
                    document.dispatchEvent(new CustomEvent('platform-auth.logout'));
                });
            }

            if (elm.ports.clearQueryString) {
                elm.ports.clearQueryString.subscribe(() => {
                    const newUrl = window.location.href.replace(window.location.search, '');
                    window.history.pushState(null, '', newUrl);
                });
            }

            if (elm.ports.setPageTitle) {
                elm.ports.setPageTitle.subscribe((title) => {
                    document.title = title;
                });
            }

            if (elm.ports.modalShowing) {
                elm.ports.modalShowing.subscribe((showing) => {
                    if (showing) {
                        document.body.classList.add('modal-is-open');
                        keepFocusWithin('.js-modal');
                    } else {
                        document.body.classList.remove('modal-is-open');
                    }
                });
            }

            if (elm.ports.changeUrl) {
                elm.ports.changeUrl.subscribe(({ url, external, blank }) => {
                    if (external) {
                        if (blank) {
                            window.open(url);
                        } else {
                            window.location = url;
                        }
                    } else if (isIe11) {
                        // TODO (or not TODO because it's only a workaround for IE11):
                        // Elm subsites attach various event listeners which won't get cleaned up in IE when we navigate to another
                        // subsite without reloading, modern Browsers do that just fine. I've implemented a simple workaround
                        // based on the assumption that we're soon going to drop IE11 support and there won't be many IE11 users.
                        window.location = url;
                    } else {
                        window.history.pushState({}, '', url);
                        window.scrollTo({ left: 0, top: 0 });
                    }
                });
            }

            if (elm.ports.openCertificates) {
                elm.ports.openCertificates.subscribe((certificates) => {
                    const updatedCerticates = certificates.map((cert) => {
                        // eslint-disable-next-line
                        cert.name = `${props.student.firstName} ${props.student.lastName}`;
                        // The certificates app requires an uppercase "Key", so we add it here
                        // eslint-disable-next-line
                        cert.Key = cert.key;
                        return cert;
                    });

                    // The certificates app receives the certificates via window.opener so that's why we have to pollute the window object
                    window.generalCertificates = updatedCerticates.filter((cert) => cert.course === 'General English');
                    window.spinCertificates = updatedCerticates.filter((cert) => cert.course !== 'General English');
                    window.open(`/iframed-study/certificate?key=${certificates[0].key}`, 'Certificates', 'width=1000,height=600');
                });
            }

            if (elm.ports.trackEvent) {
                elm.ports.trackEvent.subscribe(([name, data]) => {
                    props.trackEvent(name, data);
                });
            }

            if (elm.ports.bubbleError) {
                elm.ports.bubbleError.subscribe((message) => {
                    console.debug(message);
                    props.onMessage({
                        type: 'error',
                        ...message,
                    });
                });
            }

            if (elm.ports.bubbleInfo) {
                elm.ports.bubbleInfo.subscribe((message) => {
                    props.onMessage({
                        type: 'info',
                        ...message,
                    });
                });
            }

            if (elm.ports.bubbleSuccess) {
                elm.ports.bubbleSuccess.subscribe((message) => {
                    props.onMessage({
                        type: 'success',
                        ...message,
                    });
                });
            }

            if (elm.ports.changeLanguage) {
                elm.ports.changeLanguage.subscribe((lang) => {
                    props.changeLanguage(lang);
                });
            }

            if (elm.ports.changeStudent) {
                elm.ports.changeStudent.subscribe((student) => {
                    props.changeStudent(student);
                });
            }

            if (elm.ports.onStudentChange) {
                subscriptions.push(
                    props.onStudentChange((student) => {
                        elm.ports.onStudentChange.send(student);
                    }),
                );
            }

            if (elm.ports.requestFromLocalStorage && elm.ports.receiveFromLocalStorage) {
                elm.ports.requestFromLocalStorage.subscribe(([key, defValue]) => {
                    const asString = localStorage.getItem(key);
                    if (asString) {
                        const parsed = JSON.parse(asString);
                        elm.ports.receiveFromLocalStorage.send([key, parsed]);
                    } else if (defValue !== null) {
                        elm.ports.receiveFromLocalStorage.send([key, defValue]);
                    }
                });
            }

            if (elm.ports.writeToLocalStorage) {
                elm.ports.writeToLocalStorage.subscribe(([key, value]) => {
                    localStorage.setItem(key, JSON.stringify(value));

                    // relay the same value back into elm land
                    if (elm.ports.receiveFromLocalStorage) {
                        elm.ports.receiveFromLocalStorage.send([key, value]);
                    }
                });
            }

            if (elm.ports.updateBlurbCache) {
                elm.ports.updateBlurbCache.subscribe((newCache) => {
                    // it is possible that multiple elm subsites will be updating the
                    // blurb cache concurrently so we need to merge whatever we receive
                    // with what is already there.
                    localStorage.setItem(localStorageKeys.i18nCache,
                        JSON.stringify(mergeBlurbCaches(getCachedBlurbs(), newCache)));
                });
            }

            if (elm.ports.clearHashFragment) {
                elm.ports.clearHashFragment.subscribe((keys) => {
                    window.location.hash = keys.reduce((hash, key) => {
                        const regex = new RegExp(`${key}=[^;]*;?`);
                        return hash.replace(regex, '');
                    }, window.location.hash);
                });
            }
        });
    });

    const unmount = (props) => elmLifecycles.unmount(props).then(() => {
        subscriptions.forEach((sub) => sub());
    });

    return {
        bootstrap,
        unmount,
        mount,
    };
}
