const defaultOpts = [{
    // required opts
    elmMain: null,
    domElementGetter: null,
}];

export default function singleSpaElm(userOpts) {
    if (!(userOpts instanceof Array) && typeof userOpts[0] !== 'object') {
        throw new Error('single-spa-elm requires an Array configuration objects');
    }

    const opts = userOpts.map((opt) => ({
        ...defaultOpts,
        ...opt,
    }));

    opts.forEach((opt) => {
        if (!opt.elmMain) {
            throw new Error(
                'single-spa-elm must be passed opts.elmMain - this is the Main module of the Elm app you are trying to mount',
            );
        }

        if (!opt.domElementGetter) {
            throw new Error(
                'single-spa-elm must be passed opts.domElementGetter function otherwise we don\'t know where to attach the Elm app',
            );
        }
    });

    return {
        bootstrap: bootstrap.bind(null, opts),
        mount: mount.bind(null, opts),
        unmount: unmount.bind(null, opts),
    };
}

// nothing much that we need to do on bootstrap
function bootstrap(opts) {
    return Promise.resolve();
}

function mount(opts, props) {
    const apps = opts.map((opt) => new Promise((resolve, reject) => {
        const root = getRootDomEl(opt);

        // we create a node inside the root node because elm 0.19 actually removed the node you attach to
        const node = document.createElement('div');
        root.appendChild(node);

        // we pass props straight through as flags. This might cause problems if your props contain things that cannot be decoded but
        // there's not much else that we can do
        // we are also allowed to pass on out own flags as arguments and the two will get merged here
        const elm = opt.elmMain.init({
            flags: {
                ...props,
                ...opt.flags,
            },
            node,
        });

        if (opt.configurePorts) {
            opt.configurePorts(elm.ports);
        }

        // resolve with the initialised elm app so that the calling code can access any ports
        resolve(elm);
    }));

    return Promise.all(apps);
}

function unmount(opts) {
    const apps = opts.map((opt) => new Promise((resolve, reject) => {
        opt.domElementGetter().innerHTML = '';
        resolve();
    }));

    return Promise.all(apps);
}

function getRootDomEl(opt) {
    const el = opt.domElementGetter();

    if (!el) {
        throw new Error(
            'single-spa-elm: domElementGetter function did not return a valid dom element',
        );
    }

    return el;
}
