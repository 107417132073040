let keepFocusWithinInitialized = false;
let firstFocusableElement;
let focusableContent;
let lastFocusableElement;

const keepFocusWithin = (selector) => {
    const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const modal = document.querySelector(selector);

    if (modal) {
        firstFocusableElement = modal.querySelectorAll(focusableElements)[0]; // eslint-disable-line
        focusableContent = modal.querySelectorAll(focusableElements);
        lastFocusableElement = focusableContent[focusableContent.length - 1];

        if (!keepFocusWithinInitialized) {
            keepFocusWithinInitialized = true;

            document.addEventListener('keydown', (e) => {
                const isTabPressed = e.key === 'Tab' || e.keyCode === 9;

                if (!isTabPressed) {
                    return;
                }

                if (e.shiftKey) { // if shift key pressed for shift + tab combination
                    if (document.activeElement === firstFocusableElement) {
                        lastFocusableElement.focus(); // add focus for the last focusable element
                        e.preventDefault();
                    }
                } else if (document.activeElement === lastFocusableElement) { // if tab key is pressed & if focused has reached to last focusable element then focus first focusable element after pressing tab
                    e.preventDefault();
                    if (firstFocusableElement) {
                        firstFocusableElement.focus();
                    }
                }
            });
        }

        if (firstFocusableElement) {
            firstFocusableElement.focus();
        }
    }
};

export { keepFocusWithin };
